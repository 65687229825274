@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --body-width: 1000px;
  --col-dark: #000;
  --col-gray-dark: #515151;
  --col-gray-light: #dfdfdf;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  /*text-decoration: underline;*/
  font-weight: bold;
}