.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background {
  width: 100%;
  background-color: var(--col-dark);
}

.container {
  width: 100%;
  max-width: var(--body-width);
  margin: 0 auto;
  padding: 0 20px;  
  padding: 40px 20px 50px 20px;
  border-top: solid 1.5px var(--col-gray-dark);
}

h1.page-heading {
  color: white;
  font-size: 3rem;
  font-weight: 300;
  padding-bottom: 10px;
  /*color: var(--col-dark);*/
}
/*
.project {
  background-color: var(--col-gray-dark);
}
*/